//@font-face {
//	font-family: 'OpenSans';
//	src: local('OpenSans'), url('OpenSans-Light.eot');
//	src: local('OpenSans'), url('OpenSans-Light.eot?#iefix') format('embedded-opentype'),
//	url('OpenSans-Light.woff') format('woff'),
//	url("OpenSans-Light.otf") format("opentype"),
//	url('OpenSans-Light.ttf')  format('truetype');
//
//	font-weight: 200;
//	font-style: normal;
//}

@font-face {
	font-family: 'OpenSans';
	src: local('OpenSans'), url('OpenSans-Light.ttf') format('truetype');

	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: local('OpenSans'), url('OpenSans-Regular.ttf') format('truetype');

	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: local('OpenSans'), url('OpenSans-SemiBold.ttf') format('truetype');

	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: local('OpenSans'), url('OpenSans-Bold.ttf') format('truetype');

	font-weight: 700;
	font-style: normal;
}
