@tailwind base;
@tailwind components;
@tailwind utilities;
@import "src/assets/fonts/OpenSans/index";

:root{
	--app-height: 100vh;
}

// Reset styles goes here...
* {
	font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
	&.unselectable {
		user-select: none;
	}
	// Small CSS hack, uncomment if you're debugging a css overflow issue
	// box-sizing: border-box;
	// background: rgba(0, 100, 0, 0.03) !important;
}

html,
body,
#root {
	height: 100%;
}

body {
	padding-bottom: env(safe-area-inset-bottom);
}

.ql-mention-list-container {
	z-index: 3000;
	.ql-mention-list {
		min-width: 220px;
		max-height: 220px;
		padding: 4px 0 8px 0;
		overflow-y: scroll;
		list-style: none;
		background: white;
		border-radius: 4px;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
		li {
			display: flex;
			align-items: center;
			width: 100%;
			height: 40px;
			cursor: pointer;
			border-radius: 4px;
			&.selected {
				background: #edf1f7;
			}
		}
		&:empty {
			display: none;
		}
	}
	.ql-mention-loading {
		padding: 5px 24px;
		background: white;
		border-radius: 4px;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
		p {
			font-size: 16px;
			font-weight: 400;
		}
	}
}

.member-calendar-container {
	.MuiPickersDay-dayDisabled p {
		color: #b1b1b1 !important;
	}
	.MuiPopover-paper {
		border-radius: 12px;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), -0.5px 0 0 #c5cee0;
		p:first-child {
			font-size: 15px;
			font-weight: bold;
			line-height: 1.6;
			color: #222b45;
		}
		.MuiPickersCalendarHeader-daysHeader span {
			font-weight: 700;
			color: #727272;
		}
		.MuiPickersCalendar-week {
			button {
				height: 32px;
				&:hover {
					background-color: rgba(49, 73, 154, 0.08);
					border-radius: 8px;
				}
			}
			.MuiPickersDay-daySelected {
				background-color: rgba(49, 73, 154, 0.08);
				border-radius: 8px;
			}
			p {
				font-size: 13px;
				font-weight: 600;
				color: #222b45;
			}
		}
	}
}

.mb-16 {
	margin-bottom: 16px;
}

.two-inputs-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&.flex-end {
		align-items: flex-end;
	}
}

.multiple-inputs-row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
	grid-gap: 16px;
	align-items: flex-start;
}

.MuiTooltip-popper {
	z-index: 100000;
}

.clear-btn {
	border-radius: 8px !important;
	background-color: #edf1f7 !important;
	padding: 8px 14px !important;
	color: #222b45 !important;
}

.save-btn {
	border-radius: 8px !important;
	padding: 8px 14px !important;
}

.white-message-text {
	color: #ffffff !important;
}

.gray-message-text {
	color: #ffffff !important;
	opacity: 0.7;
}

.ps__rail-y {
	z-index: 1300;
}

.my-masonry-grid {
	display: flex;
	width: auto;
}

.my-masonry-grid_column {
	background-clip: padding-box;
	&:nth-child(1) {
		padding-right: 8px;
	}
	&:nth-child(2) {
		padding: 0 8px;
	}
	&:nth-child(3) {
		padding-left: 8px;
	}
}
